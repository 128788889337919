@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ion-input.no-padding {
    --padding-top: 0;
    --padding-bottom: 0;
  }
}

@layer components {
  .section-heading {
    @apply text-xl ml-4 mt-5 text-primary;
  }
}
